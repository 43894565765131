<template>
  <div v-if="sf != null && sf.sform != null && device_item != null">
    <div v-if="false" class="printpageforfiche">
      <div class="panelitem">
        <div class="paneltitle">Kayıt Bilgileri</div>
        <ul class="list-group">
          <li class="list-group-item text-center">
            <img :src="device_item.barcode_url" />
            <div class="text-bold">{{ device_item.device_serial_number }}</div>
          </li>
          <li class="list-group-item">
            <div class="k">Servis Tarihi</div>
            <div class="v">
              {{ moment(sf.sform.sf_date).format("DD.MM.YYYY") }}
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="k">Müşteri</div>
                <div class="v">
                  <div>
                    {{ sf.customer.title }} ({{ sf.customer.customer_code }})
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="k">GSM No</div>
            <div class="v">{{ sf.customer.gsm_primary }}</div>
          </li>
          <li class="list-group-item">
            <div class="k">Marka Model / No</div>
            <div class="v">
              {{ device_item.device_name }} / {{ device_item.device_id }}
            </div>
          </li>
          <li class="list-group-item">
            <div class="k">Seri No</div>
            <div class="v">{{ device_item.device_serial_number }}</div>
          </li>
          <li class="list-group-item">
            <div class="k">Teslimat Adresi</div>
            <div
              class="v"
              v-html="getDeviceTakeDeliveryAddress(device_item)"
            ></div>
          </li>
        </ul>
      </div>
    </div>
    <div
      style="
        padding-top: 21mm;
        padding-left: 5mm;
        padding-right: 5mm;
        height: auto !important;
        font-size: 12px;
      "
    >
 
      <div
        style="
          font-size: 12px;
          padding-top: 7mm;
          height: 13mm;
          overflow: hidden;
        "
      >
        Cihaz Bakımı yapılacak, klavye eksik tuşları giderilecek, kozmetik
        çizikler mevcut, fan sesli çalışıyor, yeni sistem kurrulumu yapılacak.
      </div>

  
      <div
        style="
          font-size: 12px;
          padding-top: 6mm;
          height: 10mm;
          overflow: hidden;
        "
      >
        <div>Ad Soyad: Kadir Malakcıoğlu</div>
        <div>GSM: 0545 292 45 13</div>
        <div>E-Mail: kadir_m.oglu@hotmail.com</div>
      </div>

       
      <div
        style="
          font-size: 12px;
          padding-top: 10mm;
          height: 10mm;
          overflow: hidden;
        "
      >
        MSI Brova 17 Gaminp LAPTOP 16DB AMD
      </div>

 
      <div
        style="
          font-size: 12px;
          padding-top: 9mm;
          height: 20mm;
          overflow: hidden;
        "
      >
        Windows 11 sistem kurulumu yapılacak. Eksik klavye tuşları giderilecek.
        Açama kapama tuşu kontrol edilecek. Fan çok sesli çalışıyor. Kapakta
        gıcırdama var. Ölü pixel kontrolü yap. Tamirat yapılmadan önce onay
        istenecek.
      </div>
    </div>
  </div>
</template>
<script>
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {},

  data() {
    return {
      datasource: {
        qp: { uniq: "", device_id: "" },
      },
    };
  },
  created() {
    try {
      this.datasource.qp.uniq = this.$route.query.uniq;
      this.datasource.qp.device_id = this.$route.query.device_id;
      this.sf.sform.sf_uniq = this.datasource.qp.uniq;
      this.getServiceDetails();
    } catch (e) {
      utils.logError(e);
    }
  },
  methods: {
    ...mapActions(["getServiceDetails"]),

    getDeviceTakeDeliveryAddress(device_item) {
      try {
        let result = "";
        if (this.sf == null || this.sf.sform == null) return result;
        let takeAddress = device_item.delivery_items.find(
          (q) => q.type == "take"
        );
        if (takeAddress) {
          result =
            takeAddress.delivery_address +
            "<br />" +
            takeAddress.district_text +
            "/" +
            takeAddress.city_text;
        }
        return result;
      } catch (e) {
        utils.logError(e);
      }
    },
    moment(v) {
      return utils.moment(v);
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    device_item() {
      let result = this.sf.devices;
      if (this.datasource.qp.device_id != null) {
        result = result.find(
          (q) => q.device_id == this.datasource.qp.device_id
        );
      }

      return result;
    },
  },
  watch: {
    "sf.sform": function (e, v) {
      setTimeout(() => {
        window.print();
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif !important;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.h-100 {
  height: auto !important;
}

 
</style> 